.btns {
  font-size: 15px;
  cursor: pointer;
}
.addPage {
  position: absolute;
  right: -10px;
  bottom: -30px;
  cursor: pointer;
}

