
/* Main Tree */

.countryStyle{
  padding:20px !important;
  text-align: center !important;
}

.stateStyle{
  padding:18.6px !important;
  text-align: center !important;
}

.districtStyle{
  padding:18.6px !important;
  text-align: center !important;
}
.collegeStyle{
  padding:16.5px !important;
  text-align: center !important;
}

.appointmentStyle{
  height:7.5em !important;
    text-align: center !important; 
}
.appointmentStyle1{
  height:7.5em !important;
  text-align: center !important; 
}
.collegeStyle1{
  padding: 18.5px !important;
}
.appointmentTree{
  padding: 39.5px !important;

}

@media only screen and (min-width: 1536px) and (max-width:1540px){
  .stateStyle{
    padding:18.5px !important;
    text-align: center !important;
  }
  .districtStyle{
    padding:18.5px !important;
    text-align: center !important;
  }
  .collegeStyle{
    padding:16.40px !important;
    text-align: center !important;
  }
  .collegeStyle1{
    padding: 18.4px !important;
  }
}