.minusSquare {
    position: relative;
  }
  
  .minusSquare:before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: -8px; /* Adjust the left position as needed */
    width: 2px; /* Width of the left border */
    background-color:red; /* Border color */
  }

  .plusSquare {
    position: relative;
  }
  
  .plusSquare:before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: -8px; /* Adjust the left position as needed */
    width: 2px; /* Width of the left border */
    background-color:green; /* Border color */
  }

  .closeSquare {
    position: relative;
  }
  
  .closeSquare:before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: -8px; /* Adjust the left position as needed */
    width: 2px; /* Width of the left border */
    background-color:green; /* Border color */
  }

  .appointmentTree{
    padding: 39.5px !important;   
   }