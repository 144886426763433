/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */
@import url("https://fonts.googleapis.com/css2?family=Nunito&family=Poppins&family=Roboto&family=Secular+One&family=Source+Sans+Pro&display=swap");

body {
  margin: 0;
  font-family: "Poppins", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif !important;
}
#mainPageBlock {
  width: 100%;
  overflow: hidden;
}

.eyeVisibleIcon {
  position: absolute;
  margin-left: -3.1%;
  margin-top: 0.5%;
  font-size: 25px;
  padding: 6px 13px;
  border-radius: 0px 4px 4px 0px;
  cursor: pointer;
  /* color: #1976D2; */
}
.eyeVisibleIcon1 {
  position: absolute;
  margin-left: -3.1%;
  margin-top: 0.5%;
  font-size: 25px;
  padding: 6px 13px;
  border-radius: 0px 4px 4px 0px;
  cursor: pointer;
  /* color: #1976D2; */
}

/* !DESKTOP */
/* @media only screen and (min-width: 1900px) and (max-width: 1920px) {
  #treeStructure {
    margin-top: 3.3% !important;
  }
  #treeStructureAppointment {
    margin-top: 11.3% !important; 
  }
   #treeStructureAddressing {
    margin-top: 8.2% !important;
  }
   #treeStructureRounds {
    margin-top: 8.2% !important;
  }
   #treeStructureOnboarding {
    margin-top: 8.2% !important;
  }
  #treeStructureBranch {
    margin-top: 8.2% !important;
  }
}

@media only screen and (min-width: 1580px) and (max-width: 1600px) {
  #treeStructure {
    margin-top: 3.8% !important;
  }
  #treeStructureAppointment {
    margin-top: 12.3% !important; 
  }
   #treeStructureAddressing {
    margin-top: 9.1% !important;
  }
   #treeStructureRounds {
    margin-top: 9.2% !important;
  }
   #treeStructureOnboarding {
    margin-top: 9.2% !important;
  }
  #treeStructureBranch {
    margin-top: 9.2% !important;
  }
}

@media only screen and (min-width: 1640px) and (max-width: 1642px) {
  #treeStructure {
    margin-top: 5.8% !important;
  }
  #treeStructureAppointment {
    margin-top: 14.3% !important; 
  }
   #treeStructureAddressing {
    margin-top: 9.1% !important;
  }
   #treeStructureRounds {
    margin-top: 9.2% !important;
  }
   #treeStructureOnboarding {
    margin-top: 9.2% !important;
  }
  #treeStructureBranch {
    margin-top: 9.2% !important;
  }
} */

/* 13 inch Laptop */
/* @media only screen and (min-width: 1366px) and (max-width:1370px) {
  #treeStructure {
    margin-top: 4.8% !important;  
  }
  #treeStructureAppointment {
    margin-top: 14.5% !important; 
  }
  #treeStructureAddressing {
    margin-top: 10% !important;
  }
  #treeStructureRounds {
    margin-top: 10% !important;
  }
  #treeStructureOnboarding {
    margin-top: 10% !important;
  }
  #treeStructureBranch {
    margin-top: 10% !important;
  }
} */

/* Office Laptop */
/* @media only screen and (min-width: 1536px) and (max-width:1540px){ */
  /* #treeStructure {
    margin-top: 4.2% !important;  
  }
  #treeStructureAppointment {
    margin-top: 13.2% !important; 
  }
  #treeStructureAddressing {
    margin-top: 9.4% !important;
  }
  #treeStructureRounds {
    margin-top: 9.4% !important;
    
  }
  #treeStructureOnboarding {
    margin-top: 9.4% !important;
  }
  #treeStructureBranch {
    margin-top: 9.4% !important;
  } */
/* } */

/* Office Laptop */
/* @media only screen and (width: 1276) and (height: 577){
  #treeStructure {
    margin-top: 4.2% !important;  
  }
  #treeStructureAppointment {
    margin-top: 20.2% !important; 
  }
  #treeStructureAddressing{
    margin-top: 19.4% !important;
  }
  #treeStructureRounds {
    margin-top: 9.4% !important;
    
  }
  #treeStructureOnboarding {
    margin-top: 9.4% !important;
  }
  #treeStructureBranch {
    margin-top: 9.4% !important;
  }
} */

/* @media only screen and (min-width: 1148px) and (max-width: 1150px){
  #treeStructure {
    margin-top: 6.2% !important;
  }
  #treeStructureAppointment {
    margin-top: 13.2% !important; 
  }
  #treeStructureAddressing {
    margin-top: 9.4% !important;
  }
  #treeStructureRounds {
    margin-top: 9.4% !important;
  }
  #treeStructureOnboarding {
    margin-top: 9.4% !important;
  }
  #treeStructureBranch {
    margin-top: 9.4% !important;
  }
} */



/* Office Laptop */

#titleContainer {
  position: fixed;
}
#landingPage2 {
  position: fixed;
  top: 14vh;
}


.items-wrapper {
  display: flex;
  flex-wrap: nowrap;
  /* gap: 15px; */
}
.item {
  border-right: 2px solid #2e3840;
  padding: 5px 10px;
}
.modalForChangeGroup MuiModal-backdrop{
  color:"red";
  background-color:"transparent" !important
}