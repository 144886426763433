
/* Appointment To Addressing Style */

.appToAddCountry{
    padding:20px !important;
    text-align: center !important;  
}
.appToAddState{
    padding:18.6px !important;
  text-align: center !important;
}
.appToAddDistrict{
    padding:18.6px !important;
  text-align: center !important;   
}
.appToAddCollege{
    padding:18.5px !important;
    text-align: center !important; 
}
.appToAddAppointment{
    height:7.5em !important;
    text-align: center !important;  
}
.appToAddAppointment1{
    height:7.5em !important;
    text-align: center !important;    
}
.roundButton_1{
background-color: #5A96E3;
}
.roundButton_1:hover{
background-color: #2579e7;
color: white;
}
.roundButton_2{
background-color: #5B9A8B;
}
.roundButton_2:hover{
background-color: #028668c2;
color: white;
}
.roundButton_3{
background-color: #9EA1D4;
}
.roundButton_3:hover{
background-color: #565fff;
color: white;
}

@media only screen and (min-width: 1536px) and (max-width:1540px){
    .appToAddState{
        padding:18.5px !important;
      text-align: center !important;
    }
    .appToAddDistrict{
        padding:18.5px !important;
      text-align: center !important;   
    }
    .appToAddCollege{
        padding:18.4px !important;
        text-align: center !important; 
    }
    .appToAddAppointment{
        height:7.5em !important;
        text-align: center !important;  
    }
    .appToAddAppointment1{
        height:7.5em !important;
    text-align: center !important;   
    }
  }


  @media only screen and (min-width: 1366px) and (max-width:1370px) {
    .appToAddState{
        padding:18.6px !important;
      text-align: center !important;
    }
    .appToAddDistrict{
        padding:18.6px !important;
      text-align: center !important;   
    }
    .appToAddCollege{
        padding:18.5px !important;
        text-align: center !important; 
    }
    .appToAddAppointment{
        height:7.5em !important;
        text-align: center !important;  
    }
    .appToAddAppointment1{
        height:7.5em !important;
    text-align: center !important;
    }
  }

/* Addressing to Rounds Style */

.addToRoundsCountry{
    padding:20px !important;
    text-align: center !important; 
}
.addToRoundsState{
    padding:18.6px !important;
    text-align: center !important; 
}
.addToRoundsDistrict{
    padding:18.6px !important;
    text-align: center !important; 
}
.addToRoundsCollege{
    padding:18.5px !important;
    text-align: center !important;  
}
.addToRoundsAppointment{
    height:7.5em !important;
    text-align: center !important;  
}
.addToRoundsAppointment1{
    height:7.5em !important;
    text-align: center !important;  
}


@media only screen and (min-width: 1536px) and (max-width:1540px){
    .addToRoundsState{
        padding:18.5px !important;
        text-align: center !important; 
    }
    .addToRoundsDistrict{
        padding:18.5px !important;
        text-align: center !important; 
    }
    .addToRoundsCollege{
        padding:18.4px !important;
        text-align: center !important;  
    }
    .addToRoundsAppointment{
        height:7.5em !important;
        text-align: center !important;   
    }
    .addToRoundsAppointment1{
        height:7.5em !important;
    text-align: center !important;  
    }
}

@media only screen and (min-width: 1366px) and (max-width:1370px) {
    .addToRoundsState{
        padding:18.6px !important;
        text-align: center !important; 
    }
    .addToRoundsDistrict{
        padding:18.6px !important;
        text-align: center !important; 
    }
    .addToRoundsCollege{
        padding:18.5px !important;
        text-align: center !important;  
    }
    .addToRoundsAppointment{
        height:7.5em !important;
        text-align: center !important; 
    }
    .addToRoundsAppointment1{
        height:7.5em !important;
    text-align: center !important;
    }

}

/* Rounds To ShortList Style */

.roundsToShortCountry{
    padding:20px !important;
    text-align: center !important; 
}
.roundsToShortState{
    padding:18.6px !important;
    text-align: center !important;  
}
.roundsToShortDistrict{
    padding:18.6px !important;
    text-align: center !important;  
}
.roundsToShortCollege{
    padding:18.5px !important;
    text-align: center !important; 
}
.roundsToShortAppointment{
    height:7.5em !important;
    text-align: center !important;   
}
.roundsToShortAppointment1{
    height:7.5em !important;
    text-align: center !important;  
}

@media only screen and (min-width: 1536px) and (max-width:1540px){
    .roundsToShortState{
        padding:18.5px !important;
        text-align: center !important;  
    }
    .roundsToShortDistrict{
        padding:18.5px !important;
        text-align: center !important;  
    }
    .roundsToShortCollege{
        padding:18.4px !important;
        text-align: center !important; 
    }
    .roundsToShortAppointment{
        height:7.5em !important;
        text-align: center !important;  
    }
    .roundsToShortAppointment1{
        height:7.5em !important;
        text-align: center !important;   
    }
}

@media only screen and (min-width: 1366px) and (max-width:1370px) {
    .roundsToShortState{
        padding:18.6px !important;
        text-align: center !important;  
    }
    .roundsToShortDistrict{
        padding:18.6px !important;
        text-align: center !important;  
    }
    .roundsToShortCollege{
        padding:18.5px !important;
        text-align: center !important; 
    }
    .roundsToShortAppointment{
        height:7.5em !important;
    text-align: center !important; 
    }
    .roundsToShortAppointment1{
        height:7.5em !important;
    text-align: center !important; 
    }
}

/* Branch To Journey Style */

.branchJourneyCountry{
    padding:20px !important;
    text-align: center !important; 
}
.branchJourneyState{
    padding:18.6px !important;
    text-align: center !important;  
}
.branchJourneyDistrict{
    padding:18.6px !important;
    text-align: center !important;  
}
.branchJourneyCollege{
    padding:18.5px !important;
    text-align: center !important; 
}
.branchJourneyAppointment{
    height:7.5em !important;
    text-align: center !important;  
}
.branchJourneyAppointment1{
    height:7.5em !important;
    text-align: center !important;  
}

@media only screen and (min-width: 1536px) and (max-width:1540px){
    .branchJourneyState{
        padding:18.5px !important;
        text-align: center !important;  
    }
    .branchJourneyDistrict{
        padding:18.5px !important;
        text-align: center !important;  
    }
    .branchJourneyCollege{
        padding:18.4px !important;
        text-align: center !important; 
    }
    .branchJourneyAppointment{
        height:7.5em !important;
    text-align: center !important;    
    }
    .branchJourneyAppointment1{
        height:7.5em !important;
        text-align: center !important;    
    }
}

@media only screen and (min-width: 1366px) and (max-width:1370px) {
    .branchJourneyState{
        padding:18.6px !important;
        text-align: center !important;         
    }
    .branchJourneyDistrict{
        padding:18.6px !important;
        text-align: center !important;  
    }
    .branchJourneyCollege{
        padding:18.5px !important;
        text-align: center !important; 
    }
    .branchJourneyAppointment{
        height:7.5em !important;
        text-align: center !important;  
    }
    .branchJourneyAppointment1{
        height:7.5em !important;
        text-align: center !important; 
    } 
}

/* OnBoarding Details Style */

.onBoardingCountry{
    padding:20px !important;
    text-align: center !important; 
}
.onBoardingState{
    padding:18.6px !important;
    text-align: center !important;  
}
.onBoardingDistrict{
    padding:18.6px !important;
    text-align: center !important;  
}
.onBoardingCollege{
    padding:18.5px !important;
    text-align: center !important; 
}
.onBoardingAppointment{
    height:7.5em !important;
    text-align: center !important;  
}

@media only screen and (min-width: 1536px) and (max-width:1540px){
    .onBoardingState{
        padding:18.5px !important;
        text-align: center !important;  
    } 
    .onBoardingDistrict{
        padding:18.5px !important;
        text-align: center !important;  
    }
    .onBoardingCollege{
        padding:18.4px !important;
        text-align: center !important; 
    }
    .onBoardingAppointment{
        height:7.5em !important;
    text-align: center !important;   
    }
}

@media only screen and (min-width: 1366px) and (max-width:1370px) {
    .onBoardingState{
        padding:18.6px !important;
        text-align: center !important;  
    } 
    .onBoardingDistrict{
        padding:18.6px !important;
        text-align: center !important;  
    }
    .onBoardingCollege{
        padding:18.5px !important;
        text-align: center !important; 
    }
    .onBoardingAppointment{
        height:7.5em !important;
        text-align: center !important; 
    }
}