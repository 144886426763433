.loader {
  height: 120px;
  width: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.loader div {
  height: 30px;
  width: 30px;
  position: absolute;
  animation: move 2s infinite;
}
.loader div:nth-child(1) {
  background-color: #7149C6;
  box-shadow: #7149C6 0px 7px 29px 0px;
  transform: translate(-30px,-30px);
  animation-delay: -0.5s;
}

.loader div:nth-child(2) {
  background-color: #0075f6;
  box-shadow: #0075f6 0px 7px 29px 0px;
  transform: translate(30px,-30px);
  animation-delay: -1s;
}

.loader div:nth-child(3) {
  background-color: #16C79A;
  box-shadow: #16C79A 0px 7px 29px 0px;
  transform: translate(30px,30px);
  animation-delay: -1.5s;
}

.loader div:nth-child(4) {
  background-color: #FC2947;
  box-shadow: #FC2947 0px 7px 29px 0px;
  transform: translate(-30px,30px);
  animation-delay: -2s;
}
@keyframes move {
  0% {
    transform: translate(-30px, -30px);
  }

  25% {
    transform: translate(30px, -30px);
  }

  50% {
    transform: translate(30px, 30px);
  }

  75% {
    transform: translate(-30px, 30px);
  }

  100% {
    transform: translate(-30px, -30px);
  }
}