/* FONTS */
@import url("https://fonts.googleapis.com/css2?family=Changa&display=swap");
* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}
.brandLogo {
  font-size: 1.6em;
  text-decoration: none;
  font-weight: bolder;
  font-family: "Changa", sans-serif !important;
  transition: all 200ms ease-in-out;
}
.brandLogo:hover {
  color: white;
  transition: all 200ms ease-in-out;
}

#NavBarr {
  background: #b83b5e !important;
  height: 8vh !important;
  display: flex;
}
#NavMenuContaiiner {
  color: #8ed6ff !important;
  display: flex;
  align-items: center;
  gap: 30px;
  font-size: 1.2em;
  font-weight: 600;
}
.navMenuItems {
  text-decoration: none;
  color: #8ed6ff !important;
  transition: all 200ms ease-in-out;
}
.navMenuItems:hover {
  color: #f6cb47;
  transition: all 200ms ease-in-out;
}

/*! Menu Dropdown */

.dropdown {
  /* position: relative; */
  display: inline-block;
  /* flex-direction: column; */
}

.dropdownList {
  display: none;
  position: absolute;
  background-color: #f1f1f1;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.dropdownList a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.dropdown:hover .dropdownList {
  display: block;
}

.dropdownList a button {
  border: none;
  color: #222;
  font-size: 15px;
  font-weight: bold;
  font-family: "Roboto Slab", serif;
  cursor: pointer;
}
.dropdownList a button:hover {
  color: #f7971c;
}

.modalCard {
  text-align: "center";
  font-weight: "bold";
  padding: "5px";
  color: "blue";
}
#splitTreeDropdown {
  color: #fff;
  background-color: #081f37;
  padding: 10px;
  border: 1px solid white;
  border-radius: 7px;
}
#splitTreeDropdown option {
  color: white;
}

/* .ModalBlock{
  position: "absolute";
  top: "50%";
  left: "50%";
  transform: "translate(-50%, -50%)";
  width: 400;
background-color: "background.paper";
  border: "1px solid #000";
  box-shadow: 24;
  padding: 5;
  border-radius: "10px";
} */
