#userProfile {
  width: 50%;
  margin: 10% auto;
  height: auto;
  border: 1px solid #DDE6ED;
  border-radius: 10px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
}
#userProfile > header {
  width: 100%;
  height: 10%;
  background-color: #DDE6ED;
  box-shadow: 0px 3px 5px 1px #808b9663 ;
  color: #081F37;
  display: flex;
  align-items: center;
  border-bottom: "2px solid #9BA4B5",
}
#userProfile > header h1 {
  width: 95%;
  margin: 0 auto;
  
}
#userProfile > article {
  width: 100%;
  height: auto;
  margin: 0 auto;
  display: flex;
  gap: 20px;
}
#userProfile > article aside:first-child {
  flex-basis: 50%;
  display: flex;
  justify-content: space-around;
  text-align: center;
}
#userProfile > article aside:first-child figure{
  width: 100%;
  height: auto;
  flex-direction: column;
  display: flex;
  align-items: center;
  gap: 20px;
  padding: 10px;
}
#userProfile > article aside:first-child figure h3{
  font-size: larger;
  text-transform: uppercase;
}
#userProfile > article aside:first-child figure img {
  height: inherit;
  border-radius: 100%;
  object-fit: cover;
}
#userProfile > article aside:last-child {
  flex-basis: 70%;
  display: flex;
  flex-direction: column;
  height: inherit;
  justify-content: space-around;
}
#userProfile > article span {
  color: rgb(81, 79, 79);
}
#userProfile > header h1 button {
  background: transparent;
  border: none;
  float: right;
  font-size: x-large;
  font-weight: 900;
}
#userProfile > header h1 button a {
  text-decoration: none;
  color: #fff;
  transition: 1s all ease-in-out;
}
#userProfile > header h1 button a:hover {
  color: darkblue;
  transition: 1s all ease-in-out;
}

#userProfile > article aside:last-child div {
  width: 100%;
  display: flex;
}
#userProfile > article aside:last-child div span {
  width: 100px;
  text-align: left;
}
#userProfile > article aside:last-child div h5 {
  min-width: 300px;
  text-align: left;
  color: #081F37;
  font-size: medium;
}
#userProfile > article aside:last-child div span {
  color: rgb(82, 80, 80);
  font-size: 17px;
}


.gradientCustom {
  /* fallback for old browsers */
  background: #f6d365;

  /* Chrome 10-25, Safari 5.1-6 */
  background: -webkit-linear-gradient(to right bottom, rgba(246, 211, 101, 1), rgba(253, 160, 133, 1));

  /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  background: linear-gradient(to right bottom, rgba(246, 211, 101, 1), rgba(253, 160, 133, 1));
}