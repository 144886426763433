.my-select {
  width: 200px;
  font-size: 16px;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 8px;
  transition: border-color 0.2s ease-in-out;
}

.my-select:focus {
  outline: none;
  border-color: #3f51b5;
}

.my-select-placeholder::before {
  content: attr(data-placeholder);
  color: #999;
}

.my-select-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 32px;
}

.addAutocomplete {
  color: green;
  background-color: transparent;
  border: none;
  cursor: pointer;
  font-size: large;
  margin-left: -15px;
  z-index: 4;
}
.quizAssignBlock {
  width: 100%;
  height: 100vh;
  overflow-y: auto;
}
.quizAssignBlock table {
  width: 98%;
  border-collapse: collapse;
  margin: 0 auto;
}

.quizAssignBlock th {
  padding: 8px 12px;
  border: 1px solid #ccc;
  text-align: left;
}
.quizAssignBlock td {
  padding: 8px 15px;
  border: 1px solid #ccc;
  text-align: left;
}

.quizAssignBlock tbody {
  display: block;
  max-height: 90%;
}

.quizAssignBlock thead,
tbody tr {
  display: table;
  width: 100%;
  table-layout: fixed;
}
.assignButtonBlock {
  display: flex;
  flex-direction: column;
  padding: 10 0;
  gap: 10px;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 100%;
}
.assignButtonBlock > p {
  padding: 20px;
  width: 100%;
  font-size: 1.5em;
  font-weight: 600;
}
.assignButton {
  padding: 10px 20px;
  background-color: #5F9DF7 !important;
  color: #fff !important;
  border: none;
  cursor: pointer;
  border-radius: 5px;
}
.assignButton:hover {
  background-color: #396EB0 !important;
  transition:  all ease-in-out 200ms;
}
.questionListBlock > thead {
  background-color: #213555 !important;
  color: white;
}
#assignButtn{cursor: pointer;
padding: 5px 15px;
color: #111;
border: none;
border-radius: 5px;}


/* Search field */

.inputSearchContainer {
  position: relative;
  margin-left: 2%;
  margin-bottom: 20px;
  margin-top: 5.5%;

}

.tableContainer {
  position: relative;
  margin-left: 2%;
  margin-bottom: 20px;
  margin-top: 0.5%;

}
.tableContainer > p {
  padding: 10px;
  padding-left: 30px;
  width: 100%;
  font-size: 16px;
}

.inputSearchContainer > input {
  padding: 10px;
  padding-left: 30px;
  border: 0.5px solid #176B87;
  border-radius: 10px;
  width: 500px;
  font-size: 16px;
  transition: border-color 0.3s ease;
}

.inputSearchContainer > input:focus {
  border: 1px solid #ccc;
}
.searchField:focus {
  outline-color: #176B87;
}