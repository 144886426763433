#batchListBlock {
    padding: 0 0 0 10px;

  }
  #batchListBlock1{
    width: 60vw;
    margin: 0 auto;
  }
  .listHeader {
    text-align: center;
    color: #D88535;
    /* text-shadow: 1px 1px #dedede; */
    margin: 1em 0;
    text-transform: capitalize;
    font-size: 25px;
  }
  .actionBlock {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 5em 0 1em;
  }
  .actionBlock a {
    background-color: #f25620;
    border: 1px solid #176B87;
    transition: 0.3s all ease-in-out;
  }
  .actionBlock a:hover {
    background-color: #fff;
    color: #f25620;
    transition: 0.3s all ease-in-out;
  }
  .inputSearchContainer {
    position: relative;
    margin-left: 2%;
    margin-bottom: 20px;
    margin-top: 20px;
  }
  
  .inputSearchContainer > input {
    padding: 10px;
    padding-left: 30px;
    border: 0.5px solid #081F37;
    border-radius: 10px;
    width: 350px;
    font-size: 16px;
    transition: border-color 0.3s ease;
  }
  
  .inputSearchContainer > input:focus {
    border: 1px solid #ccc;
  }
  
  /* .inputSearchContainer>input:active {
      outline-color: green;
  } */
  
  .searchField:focus {
    outline-color: #081F37;
  }
  @media (max-width: 750px) {
    .actionBlock {
      flex-direction: column-reverse;
      align-items: center;
      justify-content: center;
    }
    .actionBlock>div:last-child{
      width: 100%;
    }
  }
  