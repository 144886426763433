.buttons {
  font-size: 13px;
  font-family: "Roboto Slab", serif;
  cursor: pointer;
  border-radius: 5px;
  border: none;
  padding: 5px 10px;
  /* background-color: #00408a; */
  color: #fff;
  /* color: blue; */
  /* background-color: #1668c3; */
  background-color: rgba(248, 173, 33, 0.927);
}
.buttons a {
  text-decoration: none;
}
.buttons:hover {
  /* background-color: #00408a; */
  color: #fff;
  background-color: rgba(240, 157, 3, 0.904);
}
.buttons a:hover {
  color: #fff;
}
.editing {
  color: #1668c3;
  font-size: 20px;
  cursor: pointer;
}
#addSpocButton {
  cursor: pointer !important;
}
@media (max-width:1024px){
  #refreshTool{
  position: relative !important;
}
.refreshTool1{
  position:absolute !important;
  top: -90% !important;
  left: 80% !important;
  /* bottom: 90% !important; */
  /* margin-left: 200px !important; */
  background-color: red !important;
  


}
}
